var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-distribution"},[_c('app-block-flex',[_c('app-query-bar',{attrs:{"queryConfig":_vm.queryConfig},on:{"onSearch":_vm.fetchDistribute},model:{value:(_vm.queryData),callback:function ($$v) {_vm.queryData=$$v},expression:"queryData"}})],1),_c('app-block-flex',[_c('app-spacing',[(_vm.DISTRIBUTION_ACTION.CREATE)?_c('Button',{attrs:{"type":"warning"},on:{"click":function () { return _vm.onDeviceAction(_vm.DISTRIBUTION_ACTION.CREATE, true); }}},[_vm._v(" 新建分配 ")]):_vm._e()],1),_c('app-export-bar',{on:{"onExport":_vm.onExport,"onExportAll":_vm.onExportAll}})],1),_c('app-data-table',{attrs:{"columns":_vm.DEVICE_COLUMNS,"data":_vm.devices,"allowed-permissions":Object.values(_vm.ALLOWED_PERMISSION)},on:{"onPageConfigChange":_vm.fetchDistribute},scopedSlots:_vm._u([{key:"buyer_group_type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.BUYS_TEXT[row.buyer_group_type])+" ")]}},{key:"seller_group_type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.BUYS_TEXT[row.seller_group_type])+" ")]}},{key:"order_type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.BUYS_TEXT[row.order_type])+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.DIS_STATUS[row.status])+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('app-spacing',{staticClass:"table-action",attrs:{"spacing":"sm"}},[(_vm.permissions[_vm.DISTRIBUTION_ACTION.RETURN])?_c('Button',{attrs:{"disabled":!row.status,"type":"error"},on:{"click":function () { return _vm.onDeviceAction(_vm.DISTRIBUTION_ACTION.RETURN, true, row.id); }}},[_vm._v(" 撤销 ")]):_vm._e()],1)}}]),model:{value:(_vm.queryData),callback:function ($$v) {_vm.queryData=$$v},expression:"queryData"}}),_c('app-modal',{attrs:{"value":_vm.distributionModalConfig.visible,"title":_vm.distributionActionText},on:{"on-ok":_vm.onModalConfirm,"on-cancel":function () { return _vm.onDeviceAction(null, false); }}},[_c('Form',{attrs:{"model":_vm.distributionModalData,"label-width":80}},[(_vm.distributionModalConfig.action === _vm.DISTRIBUTION_ACTION.CREATE)?[_c('FormItem',{attrs:{"label":"用户类型"}},[_c('Select',{attrs:{"placeholder":"请选择类型"},on:{"on-change":_vm.getDataOption},model:{value:(_vm.distributionModalData.buyerGroupType),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "buyerGroupType", $$v)},expression:"distributionModalData.buyerGroupType"}},_vm._l((this.statusOptions),function(option){return _c('Option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),(_vm.distributionModalData.buyerGroupType === 'channel')?_c('FormItem',{attrs:{"label":"渠道名称"}},[_c('Select',{attrs:{"filterable":"","placeholder":"请选择渠道名称"},on:{"on-select":function (v) { return _vm.getChannelFreqcard(v.value); }},model:{value:(_vm.distributionModalData.buyerId),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "buyerId", $$v)},expression:"distributionModalData.buyerId"}},_vm._l((_vm.distributionModalConfig.channelOptions),function(option){return _c('Option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1):_vm._e(),(_vm.distributionModalData.buyerGroupType === 'customer'
          && this.userType === 'ADMIN' && _vm.distributionModalData.buyerGroupType)?_c('FormItem',{attrs:{"label":"门店名称"}},[_c('Select',{attrs:{"filterable":"","placeholder":"请选择用户所在门店名称"},on:{"on-select":function (v) { return _vm.getStoreCustomer(v.value); }},model:{value:(_vm.distributionModalData.storeId),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "storeId", $$v)},expression:"distributionModalData.storeId"}},_vm._l((_vm.distributionModalConfig.customstoreOptions),function(option){return _c('Option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1):_vm._e(),(_vm.distributionModalData.buyerGroupType === 'customer')?_c('FormItem',{attrs:{"label":"用户名称"}},[_c('Select',{attrs:{"filterable":"","placeholder":"请选择用户名称"},on:{"on-select":function (V){ return _vm.getCustomStoreOptions(V.value); }},model:{value:(_vm.distributionModalData.buyerId),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "buyerId", $$v)},expression:"distributionModalData.buyerId"}},_vm._l((_vm.distributionModalConfig.customOptions),function(option){return _c('Option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.nick_name)+"（"+_vm._s(option.mobile)+"） ")])}),1)],1):_vm._e(),(_vm.distributionModalData.buyerGroupType === 'store'
          && _vm.distributionModalData.buyerGroupType)?_c('FormItem',{attrs:{"label":"门店名称"}},[_c('Select',{attrs:{"filterable":"","clearable":"","placeholder":"请选择门店名称"},on:{"on-select":function (v) { return _vm.getFreqcard(v.value); }},model:{value:(_vm.distributionModalData.buyerId),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "buyerId", $$v)},expression:"distributionModalData.buyerId"}},_vm._l((_vm.distributionModalConfig.storeOptions),function(option){return _c('Option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1):_vm._e(),_c('FormItem',{attrs:{"label":"剩余次数"}},[_vm._v(" "+_vm._s(_vm.distributionModalConfig.count)+" ")]),_c('FormItem',{attrs:{"label":"次数"}},[_c('Input',{attrs:{"placeholder":"正数为增加,负数为减少"},model:{value:(_vm.distributionModalData.num),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "num", $$v)},expression:"distributionModalData.num"}})],1),_c('FormItem',{attrs:{"label":"充值类型"}},[_c('Select',{attrs:{"placeholder":"请选择充值类型"},model:{value:(_vm.distributionModalData.orderType),callback:function ($$v) {_vm.$set(_vm.distributionModalData, "orderType", $$v)},expression:"distributionModalData.orderType"}},_vm._l((_vm.distributionModalConfig.orderTypeOptions),function(option){return _c('Option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)]:(_vm.distributionModalConfig.action === _vm.DISTRIBUTION_ACTION.RETURN)?[_vm._v(" 撤销后，对应减去充值次数，是否继续操作？ ")]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }